require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/checkboxradio');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/menu');
require('jquery-ui/ui/widgets/resizable');
require('jquery-ui/ui/widgets/selectmenu');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/tabs');
require('jquery-ui/ui/widgets/tooltip');