(function ($) {
    $.fn.extend({
        limit: function (limit, input) {
            var element = '#sp'.concat(input);
            var interval, f;
            var self = $(this);

            $(this).focus(function () {

                interval = window.setInterval(substring, 100);
            });

            $(this).keyup(function () {
                interval = window.setInterval(substring, 100);

                var valor = $(self).val();
                var tamanho = valor.length;
            });

            $(this).blur(function () {
                clearInterval(interval);
                substring();
            });

            substringFunction = "function substring(){ " +
                "var val = $(self).val();" +
                "var tamanho = val.length;" +
                "var bol = false;" +
                "if(tamanho > limit){" +
                "bol = true;" +
                "$(self).val($(self).val().substring(0,limit));" +
                "}";
            if (typeof element != 'undefined')
                substringFunction += "if($(element).html() != limit-tamanho){" +
                    "$(element).html((limit-tamanho<=0)?'0':limit-tamanho);" +
                    "strResult = '#hdn'.concat(input);" +
                    "$(strResult).val((limit-tamanho<=0)?'0':limit-tamanho);" +
                    "}";

            substringFunction += "if(bol){alert('A descrição do lembrete excedeu " + limit + " caracteres.'); } }";

            eval(substringFunction);

            substring();

        }

    });

    jQuery.fn.pop = function () {
        var top = this.get(-1);
        this.splice(this.length - 1, 1);
        return top;
    };

    jQuery.fn.shift = function () {
        var bottom = this.get(0);
        this.splice(0, 1);
        return bottom;
    };
})(jQuery || $);